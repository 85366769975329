import { navigate } from "gatsby";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "grommet";
import { Previous } from "grommet-icons";
import React from "react";
import { convertToEUR, getSimpleDateTime } from "../../service/utils";
import History from "../common/history";
import PrimaryButton from "../elements/primary_button";
import StandardText, { COLOR } from "../elements/standard_text";

const getName = (customer) =>
  `${customer.first_name || ""} ${customer.last_name || ""}`;
const getPhone = (customer) => `${customer.phone || ""}`;
const getAccessCode = (customer) => `${customer.access_code || ""}`;

const OrderView = ({ order, orders }) => {
  return (
    <Box
      width={"full"}
      justify="center"
      align="center"
      style={{ minHeight: "auto" }}
    >
      <Box
        width={"full"}
        justify="center"
        align="center"
        style={{ minHeight: "auto" }}
      >
        <Box
          gridArea="header"
          direction="row"
          align="center"
          width={"full"}
          background="#F8F8F8"
          round="small"
          style={{ minHeight: "auto" }}
          justify="between"
          pad={"small"}
          margin={{ bottom: "small" }}
        >
          <Box
            align="center"
            gap="small"
            direction="row"
            style={{ minHeight: "auto" }}
          >
            <PrimaryButton
              label={"Back"}
              onClick={() => {
                if (typeof window === "undefined") return;
                navigate("/orders", { state: { data: orders } });
              }}
              icon={<Previous size="small" color={"#fff"} />}
            />
          </Box>

          <Box align="center" gap="small" direction="column">
            <StandardText label={"       "} />
          </Box>
        </Box>

        <Box
          width={"xlarge"}
          justify="center"
          align="center"
          pad={"small"}
          gap="medium"
          style={{ minHeight: "auto" }}
        >
          <Box width={"full"} justify="center" align="center">
            <StandardText
              label={order.collection_code}
              color="brand"
              bold
              size="large"
            />
          </Box>
          <Box
            direction="row"
            gap="medium"
            justify="between"
            align="center"
            width={"full"}
            style={{ minHeight: "auto" }}
          >
            <Box width={"full"} style={{ minHeight: "auto" }}>
              <CustomCard label={"Customer"}>
                <LineValue
                  label={"Name"}
                  value={`${order?.customer ? getName(order.customer) : ""}`}
                />
                <LineValue
                  label={"Phone"}
                  value={`${order?.customer ? getPhone(order.customer) : ""}`}
                />
                <LineValue
                  label={"Access code"}
                  value={`${
                    order?.customer ? getAccessCode(order.customer) : ""
                  }`}
                />
                <LineValue
                  label={"Note"}
                  value={order?.customer_notes}
                />
              </CustomCard>
            </Box>
            <Box width={"full"} style={{ minHeight: "auto" }}>
              <CustomCard label={"General"}>
                <LineValue
                  label={"Creation"}
                  value={getSimpleDateTime(order.placed_at)}
                />
                <LineValue
                  label={"Partner"}
                  value={order.partner?.name || ""}
                  link={{
                    to: `/partners/${order?.partner?.id}`,
                    name: order.partner?.name,
                  }}
                />
                <LineValue
                  label={"Platform"}
                  value={order.platform}
                  link={{
                    to: `/catalogs/${order.catalog?.id}`,
                    name: order.catalog?.name,
                  }}
                />
                <LineValue label={"Status"} value={order.status} />
              </CustomCard>
            </Box>
          </Box>
          <Box width={"full"} style={{ minHeight: "auto" }}>
            <CustomCard label={"Details"}>
              <Table
                fill="horizontal"
                style={{
                  width: "100%",
                }}
              >
                <TableHeader style={{ background: COLOR.background }}>
                  <TableRow>
                    <TableCell scope="col" style={{ width: "500px" }}>
                      <StandardText label={"NAME"} color={COLOR.dark} bold />
                    </TableCell>
                    <TableCell scope="col" width={"50px"}>
                      <StandardText
                        label={"QUANTITY"}
                        color={COLOR.dark}
                        bold
                        textAlign="center"
                      />
                    </TableCell>
                    <TableCell scope="col" width={"90px"}>
                      <StandardText
                        label={"TOTAL"}
                        color={COLOR.dark}
                        bold
                        textAlign="center"
                      />
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {order.items.map((d) => {
                    return (
                      <TableRow
                        key={d.id}
                        style={{
                          borderBottom: `0.5px solid ${COLOR.dark}`,
                          justifyContent: "start",
                          alignContent: "start",
                        }}
                      >
                        <TableCell scope="col">
                          <StandardText
                            label={d.product_name}
                            color={COLOR.black}
                          />
                          <Box margin={{ left: "small" }}>
                            {d.options?.map((op) => (
                              <StandardText
                                label={op.name}
                                color={COLOR.dark}
                                key={op.name}
                              />
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell
                          scope="col"
                          width={"50px"}
                          verticalAlign="top"
                        >
                          <Box justify="start" align="start">
                            <StandardText
                              label={`x${d.quantity}`}
                              color={COLOR.black}
                              textAlign="center"
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          scope="col"
                          width={"90px"}
                          verticalAlign="top"
                        >
                          <StandardText
                            label={d.total}
                            color={COLOR.black}
                            textAlign="center"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {console.log('order: ', order.extra_field?.meta?.payment?.promotions )}
                  {(order.extra_field?.meta?.promotions || order.extra_field?.meta?.payment?.promotions?.promotions)?.map(p => (
                    <TableRow
                    key={p.id || p.promo_type}
                    style={{
                      borderBottom: `0.5px solid ${COLOR.dark}`,
                      justifyContent: "start",
                      alignContent: "start",
                    }}
                  >
                    <TableCell scope="col">
                      <StandardText
                        label={p.type || p.promo_type}
                        color={COLOR.error}
                      />
                    </TableCell>
                    <TableCell
                      scope="col"
                      width={"50px"}
                      verticalAlign="top"
                    >
                      <Box justify="start" align="start">
                        <StandardText
                          label={``}
                          color={COLOR.black}
                          textAlign="center"
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      scope="col"
                      width={"90px"}
                      verticalAlign="top"
                    >
                      <StandardText
                        label={p.value || (p.promo_discount_percentage ? `${p.promo_discount_percentage} %`: convertToEUR((p.promo_discount_value/100)))}
                        color={COLOR.error}
                        textAlign="center"
                      />
                    </TableCell>
                  </TableRow>
                  ))}
                  <TableRow key={"xx"}>
                    <TableCell scope="col">
                      <StandardText label={"Total"} color={COLOR.black} bold />
                    </TableCell>
                    <TableCell scope="col" width={"50px"}>
                      <StandardText
                        label={``}
                        color={COLOR.black}
                        textAlign="center"
                      />
                    </TableCell>
                    <TableCell scope="col" width={"90px"}>
                      <StandardText
                        label={order.total}
                        color={COLOR.black}
                        textAlign="center"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CustomCard>
          </Box>
          <Box
            width={"full"}
            style={{ minHeight: "auto", maxHeight: 700, overflowY: "auto" }}
          >
            <CustomCard label={"Logs"}>
              <History resource_id={order.object_id} resource_type={"order"} />
            </CustomCard>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CustomCard = ({ label, children }) => (
  <Card
    width="full"
    round="small"
    background={"#fff"}
    style={{ minHeight: "auto" }}
  >
    <Box
      pad={"small"}
      width="full"
      border={{ color: "#f2f1f1" }}
      background="#fbfbfb"
    >
      <StandardText label={label} bold />
    </Box>
    <Box pad={"small"} width="full">
      {children}
    </Box>
  </Card>
);

const LineValue = ({ label, value, link }) => (
  <Box direction="row">
    <StandardText label={`${label}: `} color={COLOR.dark} />
    &nbsp;
    {link ? (
      <Box onClick={() => navigate(link.to)}>
        <StandardText label={` ${value}`} color={COLOR.green} bold />
      </Box>
    ) : (
      <StandardText label={` ${value}`} color={COLOR.black} />
    )}
  </Box>
);

export default OrderView;
