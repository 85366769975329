const axios = require("axios");
const { baseAPI } = require("../config/config.json");
const { getToken } = require("./storage_service");

const buildHeader = () => {
  return {
    Authorization: `Bearer ` + getToken(),
  };
};
module.exports = {
  getOrders: (page) => {
    const params = {};
    if (page) {
      params["pagination[page]"] = page;
      params["pagination[pageSize]"] = 25;
    }
    return axios.get(`${baseAPI}/api/orders?sort=createdAt:DESC`, {
      headers: buildHeader(),
      params,
    });
  },
  getOrder: (id) =>
    axios.get(`${baseAPI}/api/orders/${id}?populate=*`, {
      headers: buildHeader(),
    }),
  searchOrders: ({ query, filters, page }) => {
    const params = { sort: "createdAt:DESC" };

    params["fields[0]"] = "id";
    params["fields[1]"] = "placed_at";
    params["fields[2]"] = "status";
    params["fields[3]"] = "collection_code";
    params["fields[4]"] = "customer";
    params["fields[5]"] = "platform";
    params["fields[6]"] = "partner_name";
    params["fields[7]"] = "partner_id";
    params["fields[8]"] = "catalog_name";
    params["fields[9]"] = "catalog_id";


    if (query) params._q = query;
    if (page) {
      params["pagination[page]"] = page;
      params["pagination[pageSize]"] = 100;
    } else {
      params["pagination[page]"] = 1;
      params["pagination[pageSize]"] = 100;
    }
    if (filters) {
      console.log('filter: ', filters)
      Object.keys(filters)
        .filter((key) => filters[key] !== undefined)
        .forEach((key, index) => {
          switch (key) {
            case "status":
              params[`filters[$and][${index}][status][$in]`] = filters[key];
              break;
            case "_q":
              params._q = filters[key];
              break;
            case "start_date":
              params[`filters[$and][${index}][placed_at][$gte]`] = filters[key];
              break;
            case "end_date":
              params[`filters[$and][${index}][placed_at][$lte]`] = filters[key];
              break;
            case "catalog":
            case "partner":
              params[`filters[$and][${index}][${key}][id][$eq]`] = filters[key];
              break;
            default:
              params[`filters[$and][${index}][${key}][$eq]`] = filters[key];
              break;
          }
        });
    }
    return axios.get(`${baseAPI}/api/orders`, {
      headers: buildHeader(),
      params,
    });
  },
  getSalesForceDifference: filters =>  axios.get(`${baseAPI}/api/custom/salesforce`, {
    headers: buildHeader(),
    params: filters
  }),
  syncMissingOrdersSalesforce: () =>  axios.get(`${baseAPI}/api/public/missing-orders`, {
    headers: buildHeader()
  })
};
