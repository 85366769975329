import { Check, House } from "@material-ui/icons";
import { Box } from "grommet";
import { Bike, Clock, Close } from "grommet-icons";
import React from "react";
import { EventEmitter } from "../../../components/elements/event_emitter";
import PrimaryButton from "../../../components/elements/primary_button";
import StandardText from "../../../components/elements/standard_text";
import LoadingAnimation from "../../../components/loading_animation";
import NavigationFrame from "../../../components/nav_frame";
import OrderView from "../../../components/order";
import { getOrder } from "../../../service/order_service";
import { acceptOrder, denyOrder, updateDeliveryOrder } from "../../../service/partner_service";
import { getIdAfter } from "../../../service/utils";

class Order extends React.Component {

    constructor(props) {
        super(props);
        const { location } = props;
        this.state = {
            order: undefined,
            savable: false,
            orders: location.state?.data || {}
        }
    }

    componentDidMount = () => {
        const id = getIdAfter('orders/');

        if (!id) {
            return;
        }
        this.reloadData();

    }

    reloadData = () => {
        getOrder(getIdAfter('orders/'))
            .then(async res => {
                let _order = res.data.data;
                this.setState({ order: _order });
            })
    }
    acceptOrder = () => {
        acceptOrder(this.state.order)
            .then(async res => {
                EventEmitter.dispatch("showMessage", { message: "Saved successfully" });
                this.reloadData();
            })
    }

    denyOrder = () => {
        denyOrder(this.state.order)
            .then(async res => {
                EventEmitter.dispatch("showMessage", { message: "Saved successfully" });
                this.reloadData();
            })
    }

    updateDelivery = (status) => {
        updateDeliveryOrder(this.state.order, status)
            .then(async res => {
                EventEmitter.dispatch("showMessage", { message: "Saved successfully" });
                this.reloadData();
            })
    }


    render() {
        return (
            <NavigationFrame>
                {!this.state.order ?
                    <Box width={'full'} justify='center' align="center" >
                        <LoadingAnimation />
                    </Box> :
                    <Box gridArea="header" align="center" width={'full'}
                        overflow={{ vertical: 'auto' }}
                    >
                        <Box width={'full'} pad='small' style={{minHeight: 'auto'}}>
                            <Box direction="row" justify="between" style={{minHeight: 'auto'}}>
                                <StandardText label={''} />
                                <Box direction="row" align="center" gap="small" pad={{ horizontal: 'medium' }}  style={{minHeight: 'auto'}}>

                                    {this.state.order?.status === 'CREATED' && <Box direction="row" gap="small">
                                        <PrimaryButton background={'#6DB24F'}
                                            onClick={this.acceptOrder}
                                            icon={<Check size="small" color="#fff" />}
                                            label={"Accepter"}
                                        />
                                        <PrimaryButton background={"#E92C70"}
                                            onClick={this.denyOrder}
                                            icon={<Close size="small" color="#fff" />}
                                            label={"Refuser"}
                                        />
                                    </Box>}

                                    {["ACCEPTED", "started", "arriving"].includes(this.state.order?.status) && <Box direction="row" align="center" gap="small">
                                        <StandardText label={`Statut livraison`} size='xsmall' />
                                        <PrimaryButton background={'red'}
                                            onClick={() => this.updateDelivery('started')}
                                            icon={<Clock size="small" color="#fff" />}
                                            label={"Reçu"}
                                        />
                                        <PrimaryButton background={"orange"}
                                            onClick={() => this.updateDelivery('arriving')}
                                            icon={<Bike size="small" color="#fff" />}
                                            label={"En route"}
                                        />
                                        <PrimaryButton background={"green"}
                                            onClick={() => this.updateDelivery('delivered')}
                                            icon={<House size="small" color="#fff" />}
                                            label={"Livré"}
                                        />
                                    </Box>}

                                </Box>
                            </Box>
                        </Box>
                        <OrderView
                            order={this.state.order}
                            orders={this.state.orders}
                        />
                    </Box>
                }
            </NavigationFrame>
        )
    }
}

export default Order;
